// Tweak buttons in panel headings to line up properly when pulled right
.panel-heading {
  a.btn {
    margin-top: -2px;
    &:last-child {
      margin-right: -7px;
    }
  }

  // If the button comes after a UL/OL (breadcrumb) we need to push up further
  ol + a.btn, ul + a.btn {
    margin-top: -26px;
  }
}

// Strip breadcrumb padding so it fits into panel heading properly
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-radius: 0;
}